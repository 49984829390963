import Vue from "vue";
const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
export default Vue.extend({
  name: "ProfileWaiter",
  components: {
    ProfileHeader: () => import("@components/Headers/ProfileHeader.vue"),
    RestaurantsList: () => import("@components/lists/RestaurantsList.vue"),
    UpdateProfileForm: () => import("@components/forms/UpdateProfileForm.vue"),
    BasicSelect: () => import("@components/selects/BasicSelect.vue"),
    ScheduleList: () => import("@components/lists/ScheduleList.vue"),
    BasicSwitcher: () => import("@components/switchers/BasicSwitcher.vue"),
    LanguageSelect: () => import("@components/selects/LanguageSelect.vue")
  },
  props: {
    profile: {
      type: Object,
      default: () => ({
        name: null,
        email: null,
        phone: null,
        lang: null,
        showPassword: null,
        points: 0,
        image: "",
        loading: true,
        loadingProfile: true,
        clockReservation: null,
        tab: 0,
        categories: [],
        orders: [],
        restaurants: [],
        reservations: [],
        showLoadMoreSpinner: false,
        notMoreItems: false,
        fetchOptions: { limit: 10 }
      })
    },
    schedule: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    tab: null,
    selected_head_quater: null,
    dayweek: weekDays[new Date().getDay() - 1],
    ctrlComponents: {
      settings: true,
      updateProfile: false,
      updatePassword: false
    },
    quater: {}
  }),
  computed: {
    isWaiter() {
      const rol = window.localStorage.getItem("selectedRol");
      return rol === "WAITER";
    },
    workingAts() {
      return Object.values(window.user.workingAt);
    },
    defaultSelectedQuater() {
      const workingAt = Object.values(window.user.workingAt);
      const selected = this.findSelected(workingAt);
      if (selected) return selected.id;
      const { headquater: defaultHeadquater = "" } = window.router.currentRoute.query;
      const hasDefaultHeadquater = defaultHeadquater !== "";
      if (hasDefaultHeadquater) {
        const headquater = workingAt.find(({ id }) => id === defaultHeadquater);
        if (headquater) {
          headquater.selected = true;
          return headquater.id;
        }
      }
      const [firstHeadquater = {}] = workingAt;
      firstHeadquater.selected = true;
      return firstHeadquater.id;
    }
  },
  created() {
    this.quater = Object.values(window.user.workingAt).find(headQuaters => headQuaters.selected);
    if (!this.quater) {
      const [headquater = {}] = Object.values(window.user.workingAt);
      this.quater = headquater;
    }
  },
  methods: {
    findSelected(workingAt = []) {
      return workingAt.find(headquater => headquater.selected);
    },
    selectedHeadQuater(headQuater) {
      this.selected_head_quater = Object.values(window.user.workingAt).find(headQuaters => headQuaters.id === headQuater);
      Object.values(window.user.workingAt).forEach(workingAt => {
        workingAt.selected = workingAt.id === this.selected_head_quater.id;
      });
      window.user.selectedHeadQuater = this.selected_head_quater;
      this.quater = this.selected_head_quater;
      this.$emit("handleGetQuater");
    },
    handleUpdateProfile() {
      this.ctrlComponents.settings = false;
      this.ctrlComponents.updateProfile = true;
    },
    handleSettings() {
      this.ctrlComponents.settings = true;
      this.ctrlComponents.updateProfile = false;
      this.ctrlComponents.updatePassword = false;
    }
  }
});
